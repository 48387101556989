import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth_service';
import { Alert } from 'react-bootstrap';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    try {
      await authService.login(email, password);
      navigate('/'); // Navigate to the home page after successful login
    } catch (error) {
      setError('Wrong email or password');
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="text-center mb-4">Login</h2>
              <br></br>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <br></br>
                <div className="mb-3">
                  <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <br></br>
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-block">Login</button>
                </div>
                <br></br>
              </form>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      {/* Display error message if there's an error */}
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

export default LoginPage;
