// authService.js
import axios from 'axios';
import { baseUrl } from './base_service';


const authService = {
  login: async (email, password) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      var data = { email, password };

      const response = await axios.post(`${baseUrl}login`, JSON.stringify(data),config);
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('adminId', user.id);
      return user;
    } catch (error) {
      throw new Error('Login failed');
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminId');
    

  },

  isAuthenticated: () => {
    return localStorage.getItem('token') !== null;
  }
};

export default authService;
