import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './DeleteConfirmationModal.css';

function UpdateUserModal({ show, handleClose, handleUpdateUser, user}) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      handleUpdateUser(user.id, name, email); // Pass the correct parameters
      handleClose();
    }
    setValidated(true);
};

  return (
    <Modal show={show} onHide={handleClose}  centered
    backdropClassName="custom-backdrop" >
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
          </Form.Group>
          <br></br>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
          </Form.Group>
          <br></br>
          <Form.Group controlId="formName">
            <Form.Label>Role</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              disabled
            />
            <Form.Control.Feedback type="invalid">Role is required.</Form.Control.Feedback>
          </Form.Group>
          <br>
          </br>
          <br>
          </br>
          <Button variant="primary" type="submit">
            Update User
          </Button>
          <br>
          </br>
          <br>
          </br>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateUserModal;
