import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap'; // Import Navbar and Container from react-bootstrap
import DashboardPage from './dashboard_page';
import LoginPage from './login_page';
import UserPage from './user/user_page';
import HotelPage from './hotels/hotel_page';
import HotelDetails from './hotels/hotel_detail';
import PrivateRoute from './private_route';
import AddHotel from './hotels/add_hotel';
import authService from '../services/auth_service';
import EditHotel from './hotels/edit_hotel';
import CheckinPage from './checkins/checkin_page';
import Button from 'react-bootstrap/Button';
import PrivacyPolicyAndroid from './legal_pages/privacy_policy_android';
import PrivacyPolicyIos from './legal_pages/privacy_policy_ios';
import TermsAndServices from './legal_pages/term_and_services';
import AboutUs from './legal_pages/about_us';
import DataDeletionRequest from './legal_pages/delete_account_request';
import { baseUrlAsssets } from '../services/base_service';

function LocalRoute() {
    const navigate = useNavigate();

    const handleLogout = () => {
        authService.logout();
        navigate('/');
    };

    return (
        <>
            <Navbar bg="light" expand="lg"> {/* Use Bootstrap Navbar */}
                <Container>
                    {localStorage.getItem('token') !== null && <Link className="navbar-brand" to="/">
                        <img
                            src={`${baseUrlAsssets}images/icon.png`}
                            alt=""
                            height={40} // Corrected height to 18px
                        />
                        Dashboard
                    </Link>}


                    {localStorage.getItem('token') !== null && <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>}
                </Container>
            </Navbar>

            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/' element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
                <Route path='/users' element={<PrivateRoute><UserPage /></PrivateRoute>} />
                <Route path='/hotels' element={<PrivateRoute><HotelPage /></PrivateRoute>} />
                <Route path='/checkins' element={<PrivateRoute><CheckinPage /></PrivateRoute>} />
                <Route path='/addhotel' element={<PrivateRoute><AddHotel /></PrivateRoute>} />
                <Route path='/hotels/:id' element={<PrivateRoute><HotelDetails /></PrivateRoute>} />
                <Route path='/hotels/edit/:id' element={<PrivateRoute><EditHotel /></PrivateRoute>} />
                <Route path='/android_privacy_policy' element={<PrivacyPolicyAndroid/>} />
                <Route path='/ios_privacy_policy' element={<PrivacyPolicyIos/>} />
                <Route path='/terms_and_services' element={<TermsAndServices/>} />
                <Route path='/about_us' element={<AboutUs/>} />
                <Route path='/data_delete_request_page' element={<DataDeletionRequest/>} />
                
            </Routes>
        </>
    );
}

export default LocalRoute;
