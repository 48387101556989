import React from 'react'
import { Navigate } from 'react-router-dom'
import authService from '../services/auth_service'


const PrivateRoute = ({ children }) => {

  // Add your own authentication on the below line.
  const isLoggedIn = authService.isAuthenticated;

console.log(isLoggedIn());

  if (isLoggedIn()) {
    return children;
  }
  else {
    return <Navigate to="/login"/>
  }

}

export default PrivateRoute;