import React, { useState, useEffect } from "react";
import axios from "axios";
import DeleteConfirmationModal from "./delete_user_modal";
import AddUserModal from "./add_user_modal";
import UpdateUserModal from "./update_user_model";
import Loader from "../common_component";
import { baseUrl } from '../../services/base_service';

function UserPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [userIdToAction, setUserIdToAction] = useState(null); // Store the user ID to delete

  const fetchUsers = () => {
    const yourToken = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
      },
    };
    setLoading(true);
    axios
      .get(`${baseUrl}get_all_user`, config)
      .then((response) => {
        setData(response.data);
        setLoading(false);
        console.log("API call successful");
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        console.error("API call failed:", error);
      });
  };

  const deleteUser = (id) => {
    const yourToken = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
      },
    };
    axios
      .delete(`${baseUrl}users/${id}`, config)
      .then((response) => {
        console.log("User deleted successfully");
        fetchUsers(); // Refresh user list after deletion
      })
      .catch((error) => {
        setDeleteError(error);
        console.error("Failed to delete user:", error);
      });
  };

  const addUser = (name, email, password, role = 'user') => {
    const data = {
      name: name,
      email: email,
      password: password,
      role: role
    };

    const yourToken = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
     //   ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
      },
    };

    axios.post(
      `${baseUrl}register`,
      data,
      config
    )
    .then(response => {
      console.log('User registered successfully:', response.data);
      fetchUsers(); // Refresh user list after adding a user
    })
    .catch(error => {
      console.error('Error registering user:', error.response ? error.response.data : error.message);
    });
  };

  const handleOpenModal = (id) => {
    setUserIdToAction(id);
    setShowModal(true);
  };

  const handleOpenModalInsert = () => {
    setShowModalInsert(true);
  };

  const handleOpenModalUpdate = (id) => {
    setUserIdToAction(id);
    setTimeout(() => {
      setShowModalUpdate(true);
    }, 300);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalUpdate(false);
    setShowModalInsert(false);
    setTimeout(() => {
      fetchUsers();
    }, 300);
  };

  const handleDelete = () => {
    if (userIdToAction) {
      deleteUser(userIdToAction);
      handleCloseModal();
    }
  };

  const handleAddUser = (name, email, password, role) => {
    addUser(name, email, password, role);
    handleCloseModal();
  };

  const updateUser = (id, name, email) => {
    const data = { name, email };

    const yourToken = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
      },
    };

    axios.put(`${baseUrl}users/update/${id}`, data, config)
      .then(response => {
          console.log('User updated:', response.data);
          fetchUsers(); // Refresh the user list after update
      })
      .catch(error => {
          console.error('There was an error updating the user!', error);
      });
  };

  const handleUpdateUser = (id, name, email) => {
    if (userIdToAction) {
      updateUser(userIdToAction, name, email);
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []); // Fetch users only once when the component mounts

  if (loading) {
    return (
      <div className="center">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <>
      <br />
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-5"></div>
          <div className="col-md-2">
            <button
              onClick={handleOpenModalInsert}
              className="btn btn-primary"
            >
              Add new user
            </button>
            <AddUserModal
              show={showModalInsert}
              handleClose={handleCloseModal}
              handleAddUser={handleAddUser}
            />
          </div>
          <div className="col-md-5"></div>
        </div>
      </div>
      <br />
      <br />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <tr key={user.id}>
              <th scope="row">{user.id}</th>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <button
                  onClick={() => handleOpenModalUpdate(user.id)}
                  className="btn btn-primary"
                >
                  EDIT
                </button>
                <UpdateUserModal
                  show={showModalUpdate}
                  handleClose={handleCloseModal}
                  handleUpdateUser={handleUpdateUser}
                  user={user}
                />
              </td>
              <td>
                <button
                  onClick={() => handleOpenModal(user.id)}
                  className="btn btn-primary"
                >
                  DELETE
                </button>
                <DeleteConfirmationModal
                  show={showModal}
                  handleClose={handleCloseModal}
                  handleDelete={handleDelete}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default UserPage;
