import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DeleteConfirmationModal.css'; // Import CSS file for custom styles

function DeleteConfirmationModal({ show, handleClose, handleDelete }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdropClassName="custom-backdrop" // Apply custom backdrop class
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
