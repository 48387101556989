// src/components/HotelDetails.js
import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../user/delete_user_modal';
import Loader from '../common_component';
import { baseUrl } from '../../services/base_service';



const HotelDetails = () => {
    let { id } = useParams();
    const [hotel, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [hotelIdToAction, setUserIdToAction] = useState(null);
    const [error,setError] = useState(false);

    const fetchHotel = () => {
      const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
        },
      };
        setLoading(true);
        axios.get(`${baseUrl}hotel/${id}`,config)
            .then(response => {
                setData(response.data);
                setLoading(false);
                console.log('API call successful');
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                console.error('API call failed:', error);
            });
    }

    useEffect(() => {
        fetchHotel();
    }, []);

    const navigate = useNavigate();
    const handleEditNavigate = (id) => {
      navigate(`/hotels/edit/${id}`);
    }

    const handleOpenModal = (id) => {
        setUserIdToAction(id); // Set the user ID to delete
        setShowModal(true);
      };

      const handleDelete = async () => {
        if (hotelIdToAction) {
        await  deleteHotel(hotelIdToAction);
          handleCloseModal();
          navigate("/hotels");
        }
      };

      const handleCloseModal = () => {
        setShowModal(false);        
      };

    const deleteHotel = (id) => {
      const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
        },
      };
        axios.delete(`${baseUrl}hotel_delete/${id}`,config)
          .then(response => {
            console.log('hotel deleted successfully');
          })
          .catch(error => {
            console.error('Failed to delete hotel:', error);
          });
      }


    if (loading) {
      return <div className='center'><Loader/></div>;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!hotel) {
        return <div>No data available</div>;
      }
    
    return (
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Name:- {hotel.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Address:- {hotel.address}</Card.Subtitle>
                    <Card.Text>About:- {hotel.about}</Card.Text>
                    <Card.Text>Latitude:- {hotel.latitude}</Card.Text>
                    <Card.Text>Longitude:- {hotel.longitude}</Card.Text>
                    <Card.Text>Added by: Admin :- {hotel.admin_id}</Card.Text>
                    <Row>
                        {hotel?.images.map((image, index) => (
                            <Col key={index} xs={6} md={4} lg={3}>
                                <img
                                src = {image.name}
                                    alt={`Image ${index}`}
                                    className="img-fluid"
                                />
                            </Col>
                        ))}
                    </Row>
                    <br></br>
                    <div className='container'>
                    <div class="row">
                      <div className='col-md-2'>
                      <button onClick={() => handleEditNavigate(hotel.id)} className="btn btn-primary">Edit</button>
                   
                      </div>
                      <div className='col-md-8'></div>
                      <div className='col-md-2'>
                   <button onClick={() => handleOpenModal(hotel.id)} className="btn btn-danger">DELETE</button>
                  
                   </div>
                     </div>
                    </div>
                    
              <DeleteConfirmationModal 
                show={showModal} 
                handleClose={handleCloseModal} 
                handleDelete={handleDelete} 
              />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default HotelDetails;
