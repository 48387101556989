import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../services/base_service';
// src/components/HotelDetails.js


const EditHotel = () => {
    let { id } = useParams();
    const [hotel, setHotel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [images, setImages] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        about: '',
        address: '',
        admin_id: localStorage.getItem('adminId'),
        address: '',
        latitude: '',
        longitude: '',
    });

    const fetchHotel = () => {
        const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
        },
      };
        setLoading(true);
        axios.get(`${baseUrl}hotel/${id}`,config)
            .then(response => {
                setHotel(response.data);
                setFormData({
                    name: response.data.name,
                    about: response.data.about,
                    admin_id: localStorage.getItem('adminId'),
                    address: response.data.address,
                    latitude: response.data.latitude,
                    longitude: response.data.longitude, // Corrected longitude assignment
                });
                setLoading(false);
                console.log('API call successful');
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                console.error('API call failed:', error);
            });
    }


    const handleDeleteImage = async (id) => {
      const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
        },
      };
        setLoading(true);
        axios.delete(`${baseUrl}image/${id}`,config)
            .then(response => {
                fetchHotel();

                console.log('API call successful');
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                console.error('API call failed:', error);
            });
    }

    useEffect(() => {
        console.log(localStorage.getItem('adminId'));
        fetchHotel();
    }, []);

    const updateHotelData = async () => {
        try {
            const data = { 
                name: formData.name, 
                about: formData.about, 
                address: formData.address, 
                admin_id: localStorage.getItem('adminId'),
                latitude: formData.latitude, 
                longitude: formData.longitude 
            };
    
            const yourToken = localStorage.getItem('token');
            const config = {
              headers: {
                "Content-Type": "application/json",
                ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
              },
            };
    
            const response = await axios.put(`${baseUrl}hotel/${id}`, data, config);
            
            if (images.length !== 0) {
                await uploadImages();
            }
            fetchHotel(); // Fetch hotel data after update
            console.log('Hotel data updated successfully:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating hotel data:', error);
            throw new Error('Failed to update hotel data');
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);
    };

    const uploadImages = async () => {
        const formData2 = new FormData();
        images.forEach((image, index) => {
            formData2.append('images', image);
        });
        formData2.append('hotel_id', id);
        formData2.append('admin_id', formData.admin_id);

        const yourToken = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
          },
        };
        try {
            const response = await axios.post(`${baseUrl}upload_images`, formData2,config
                );
            console.log('Images uploaded successfully:', response.data);
            // Handle success, e.g., show a success message or update state
        } catch (error) {
            console.error('Error uploading images:', error);
            // Handle error, e.g., show an error message
        }
    };





    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g., send data to server
        updateHotelData();
        console.log(formData);
    };



    return (
        <div className="container mt-5">
            <h2>Hotel Form</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </Form.Group>
                <br />
                <Form.Group controlId="about">
                    <Form.Label>About</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="about"
                        value={formData.about}
                        onChange={handleChange}
                    />
                </Form.Group>
                <br />
                <Form.Group controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </Form.Group>
                <br></br>
                <div className='row'>
                    <div className='col-md-6'><Form.Group controlId="latitude">
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control
                            type="text"
                            name="latitude"
                            value={formData.latitude}
                            pattern="[0-9]*\.?[0-9]*"
                            onChange={handleChange}
                        />
                    </Form.Group></div>
                    <br></br>
                    <div className='col-md-6'><Form.Group controlId="longitude">
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control
                            type="text"
                            name="longitude"
                            pattern="[0-9]*\.?[0-9]*"
                            value={formData.longitude}
                            onChange={handleChange}
                        />
                    </Form.Group></div>

                </div>
                <br></br>
                <Row>
                    {hotel?.images.map((image, index) => (
                        <Col key={index} xs={6} md={4} lg={3}>
                            <div className="image-container">

                                <div
                                    className="delete-button"
                                    onClick={() => handleDeleteImage(image.id)}
                                >
                                    Delete
                                </div>

                                <img
                                    src={image.name}
                                    alt={`Image ${index}`}
                                    className="img-fluid"
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
                <br></br>
                <Form.Group controlId="images">
                    <Form.Label>Images</Form.Label>
                    <Form.Control
                        type="file"
                        multiple
                        onChange={handleImageChange}
                    />
                </Form.Group>
                <br></br>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default EditHotel;
