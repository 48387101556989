import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function PrivacyPolicyAndroid() {
  return (
    <Container className="mt-5">
      <Row>
        <Col className="shadow p-4">
          <h1 className="text-center">
            <u>
              Privacy Policy of <strong>Uphead Management Consulting Private Limited</strong>
            </u>
          </h1>
          <br />
          <br />
          <p className="text-center">
            This Privacy Policy is only applicable if you have downloaded this App from the developer{' '}
            <span style={{ fontWeight: 'bold' }}>Uphead Management Consulting Private Limited</span> having play store developer id:{' '}
            <a href="https://play.google.com/store/apps/developer?id=">
              Uphead Management Consulting Private Limited
            </a>{' '}
            as link mentioned below. This SERVICE is provided by Uphead Management Consulting Private Limited at no cost and is intended for use as it is.
          </p>
          <p className="text-center">
            ( Play Store Developer ID Link:{' '}
            <a href="https://play.google.com/store/apps/developer?id=">
              https://play.google.com/store/apps/developer?id=
            </a>{' '}
            )
          </p>
          <p className="text-center">
            This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.
          </p>
          <p className="text-center">
            If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Information Collection and Use</u>
          </h2>
          <br />
          <p className="text-center">
            For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to access to media, access to files, access to photo, contact info, device info. The information that I request will be{' '}
            <span style={{ fontWeight: 'bold' }}>retained on your device </span>and is{' '}
            <span style={{ fontWeight: 'bold' }}>not collected by me</span> in any way.
          </p>
          <p className="text-center">
            The app does use third party services that may collect information used to identify you.
          </p>
          <div>
            <p className="text-center">Link to privacy policy of third party service providers used by the app</p>
            <ul className="text-center">
              <li>
                <a href="https://www.google.com/policies/privacy/" target="_blank">
                  Google Play Services
                </a>
              </li>
            </ul>
          </div>
          <br />
          <br />
          <h2 className="text-center">
            <u>Log Data</u>
          </h2>
          <br />
          <p className="text-center">
            {' '}
            I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.{' '}
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Cookies</u>
          </h2>
          <br />
          <p className="text-center">
            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
          </p>
          <p className="text-center">
            This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.{' '}
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Service Providers</u>
          </h2>
          <br />
          <p className="text-center">
            {' '}
            I may employ third-party companies and individuals due to the following reasons:
          </p>
          <ul className="text-center">
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p className="text-center">
            {' '}
            I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Security</u>
          </h2>
          <br />
          <p className="text-center">
            {' '}
            I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Links to Other Sites</u>
          </h2>
          <br />
          <p className="text-center">
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Children’s Privacy</u>
          </h2>
          <br />
          <p className="text-center">
            These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Changes to This Privacy Policy</u>
          </h2>
          <br />
          <p className="text-center">
            {' '}
            I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
          </p>
          <br />
          <br />
          <h2 className="text-center">
            <u>Contact Us</u>
          </h2>
          <br />
          <p className="text-center">
            If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at Email:{' '}
            <strong>info@upheadstrategy.com</strong>
          </p>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolicyAndroid;

