import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DataDeletionRequest = () => {
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      //
      setShowToast(true);
    //  setEmail('');
      //setValidated(false);

      // Optional: Reset form validation state (if needed)
       form.reset();
    }
    setValidated(true);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="6">
          <h1 className="mt-5">Request Data Deletion</h1>
          <p>If you would like to delete your data from our app, please enter your email address below and submit the request. We will process your request as soon as possible.</p>
          
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="danger" type="submit" className="mt-3">
              Submit Request
            </Button>
          </Form>

          <Toast
            onClose={() => {
              setShowToast(false);
              setEmail('');
              setValidated(false);
            }}
            show={showToast}
            delay={3000}
            autohide
            style={{ position: 'absolute', top: 20, right: 20 }}
          >
            <Toast.Header>
              <strong className="mr-auto">Request Submitted</strong>
            </Toast.Header>
            <Toast.Body>Your request has been successfully submitted!</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </Container>
  );
};

export default DataDeletionRequest;
