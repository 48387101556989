import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {useNavigate } from 'react-router-dom';

const DashboardPage = () => {

  const navigate = useNavigate();

  const handleUser = () => {
    navigate('/users');
};
const handleHotel = () => {
  navigate('/hotels');
};
const handleCheckin = () => {

  navigate('/checkins');
};
  

  return (
    <div className="container">
  <div className="row">
    <div className="col-md-4 mb-4">
      <Card>
        <Card.Img variant="top" src="https://cdn.pixabay.com/photo/2016/10/29/10/14/abstract-1780378_1280.png" />
        <Card.Body>
          <Card.Title>Users</Card.Title>
          <Button onClick={handleUser} on variant="primary">Go</Button>
        </Card.Body>
      </Card>
    </div>
    <div className="col-md-4 mb-4">
      <Card>
        <Card.Img variant="top" src="https://cdn.pixabay.com/photo/2016/10/29/10/14/abstract-1780378_1280.png" />
        <Card.Body>
          <Card.Title>Hotels</Card.Title>

          <Button onClick={handleHotel} variant="primary">Go</Button>
        </Card.Body>
      </Card>
    </div>
    <div className="col-md-4 mb-4">
      <Card>
        <Card.Img variant="top" src="https://cdn.pixabay.com/photo/2016/10/29/10/14/abstract-1780378_1280.png" />
        <Card.Body>
          <Card.Title>Active Check-ins</Card.Title>

          <Button onClick={handleCheckin} variant="primary">Go</Button>
        </Card.Body>
      </Card>
    </div>
  </div>
</div>

  );
};

export default DashboardPage;
