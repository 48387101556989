import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import './DeleteConfirmationModal.css';
import Loader from '../common_component';
import { baseUrl } from '../../services/base_service';


function HotelPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchHotels = () => {
    const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
        },
      };
    setLoading(true);
    axios.get(`${baseUrl}hotels`,config)
      .then(response => {
        setData(response.data);
        setLoading(false);
        console.log('API call successful');
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error('API call failed:', error);
      });
  }

  const handleOpenAddHotel = () => {
    navigate(`/addhotel`);
  };

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/hotels/${id}`);
  }

  useEffect(() => {
    fetchHotels();
  }, []); // Empty dependency array ensures that useEffect runs only once, when the component mounts

  if (loading) {
    return <div className='center'><Loader/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || data.length === 0) {
    return <> <br></br>
    <br></br>
    
    <br></br>
    <div className='container'>
      <div className='row'>
      <div className='col-md-5'></div>  
      <div className='col-md-2'><button onClick={() => handleOpenAddHotel()} className="btn btn-primary">Add new hotel</button></div>  
      <div className='col-md-5'></div>  
      </div>
    </div>
    <br></br>
    <br></br></>
  }

  return (
    <>
    <br></br>
    <br></br>
    <div className='container'>
      <div className='row'>
      <div className='col-md-5'></div>  
      <div className='col-md-2'><button onClick={() => handleOpenAddHotel()} className="btn btn-primary">Add new hotel</button></div>  
      <div className='col-md-5'></div>  
      </div>
    </div>
    <br></br>
    <br></br>
      <div className="container">
  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
    {data?.map(hotel => (
      <div className="col mb-4" key={hotel.id}>
        <div className="card">
          <div className="image-container">
            {hotel.images.length > 0 && hotel.images[0].name ? (
              <img src={hotel.images[0].name} className="card-img-top" alt={hotel.name} />
            ) : (
              <img src="https://cdn.pixabay.com/photo/2020/04/17/12/28/pool-5055009_1280.jpg" className="card-img-top" alt={hotel.name} />
            )}
          </div>
          <div className="card-body">
            <h5 className="card-title">{hotel.name}</h5>
            <p className="card-text">{hotel.about.length > 100 ? `${hotel.about.substring(0, 100)}...` : hotel.about}
            </p>
            <button className="btn btn-primary" onClick={() => handleNavigate(hotel.id)}>View</button>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>




    </>
  );
}

export default HotelPage;
