import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import '../hotels/DeleteConfirmationModal.css';
import Button from 'react-bootstrap/Button';
import Loader from '../common_component';
import { baseUrl } from '../../services/base_service';


function CheckinPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchCheckins = () => {
    const yourToken = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...(yourToken && { "Authorization": `Bearer ${yourToken}` })
      },
    };
    setLoading(true);
    axios.get(`${baseUrl}active_checkins`,config)
      .then(response => {
        setData(response.data);
        setLoading(false);
        console.log('API call successful');
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error('API call failed:', error);
      });
  }

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/hotels/${id}`);
  }

  const handleUserNavigate = (id) => {
    navigate(`/user/${id}`);
  }

  useEffect(() => {
    fetchCheckins();
  }, []); // Empty dependency array ensures that useEffect runs only once, when the component mounts

  if (loading) {
    return <div className='center'><Loader/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

   if (!data || data.length === 0) {
      return <> <br></br>
      <br></br>
      
      <br></br>
      <div className='container'>
        <div className='row'>
        <div className='col-md-4'></div>  
        <div className='col-md-4'>No Active checkin as of now.</div>  
        <div className='col-md-4'></div>  
        </div>
      </div>
      <br></br>
      <br></br></>
    }


  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
      <div className="container">
  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
    {data?.map(checkin => (
      <div className="col mb-4" key={checkin.id}>
        <div className="card">
          <div className="image-container">
            {checkin.hotel.images.length > 0 && checkin.hotel.images[0].name ? (
              <img src={checkin.hotel.images[0].name} className="card-img-top" alt={checkin.hotel.name} />
            ) : (
              <img src="https://cdn.pixabay.com/photo/2020/04/17/12/28/pool-5055009_1280.jpg" className="card-img-top" alt={checkin.hotel.name} />
            )}
          </div>
          <div className="card-body">
            <h5 className="card-title">User:- <Button variant="outline-primary" onClick={() => handleNavigate(checkin.user.id)}>{checkin.user.name}</Button> </h5> 
            <p className="card-text">Hotel:- <Button variant="outline-primary" onClick={() => handleNavigate(checkin.hotel.id)}>{checkin.hotel.name}</Button>
        </p>

            <button className="btn btn-primary" onClick={() => handleNavigate(checkin.hotel.id)}>View</button>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>




    </>
  );
}

export default CheckinPage;
