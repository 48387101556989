// App.js
import React from 'react';
import LocalRoute from './components/local_routes';


function App() {
  return (
    <LocalRoute/>
  );
}

export default App;
