import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const TermsAndServices = () => {
  return (
    <Container fluid className="terms-and-services mt-5">
      <h1 className="text-center">Terms and Services for TBK Villa Booking App</h1>

      <Row>
        <Col>
          <h2>1. Introduction</h2>
          <p>
            Welcome to TBK Villas. These Terms and Services ("Terms") govern your use of our mobile application and services provided by us. By using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the App.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>2. Services</h2>
          <p>
            The App provides users with a platform to search for, check in, and manage villa rentals in Goa, India. Our services include:
          </p>
          <ul>
            <li>Browsing available villas</li>
            <li>Booking and reserving villas</li>
            <li>Payment processing</li>
            <li>Customer support</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>3. User Registration</h2>
          <p>
            To use certain features of the App, you must register for an account. You agree to provide accurate, current, and complete information during the registration process.
          </p>
          <p>
            You are responsible for safeguarding your password and agree not to disclose your password to any third party.
          </p>
          <p>
            You must immediately notify us of any unauthorized use of your account.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>4. Booking and Payment</h2>
          <p>
            All bookings are subject to availability and acceptance by us.
          </p>
          <p>
            You agree to pay the full amount of the booking, including any applicable taxes and fees, at the time of booking.
          </p>
          <p>
            We accept various payment methods, including credit cards, debit cards, and digital wallets.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>5. Guest Responsibilities</h2>
          <p>
            You agree to treat the property with respect and adhere to the house rules provided by the villa owner.
          </p>
          <p>
            You are responsible for any damages caused to the property during your stay.
          </p>
          <p>
            The maximum occupancy of the villa must not be exceeded without prior permission from the owner.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>6. Limitation of Liability</h2>
          <p>
            We act only as an intermediary between you and the villa owners. We are not responsible for any issues related to the property, including but not limited to cleanliness, safety, or availability.
          </p>
          <p>
            We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the App or the properties listed on the App.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>7. Modifications to Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the App. Your continued use of the App after any modifications indicates your acceptance of the new Terms.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>8. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the laws of India. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of Goa, India.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please contact us at info@upheadstrategy.com.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="text-center">
            By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms and Services.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndServices;
