import axios from 'axios';
import React, { useState, useRef } from 'react';
import { Form, Button, Toast, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseUrl } from '../../services/base_service';

const AddHotel = () => {
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    about: '',
    admin_id: localStorage.getItem('adminId'),
    address: '',
    latitude: '',
    longitude: '',
  });

  const [showToast, setShowToast] = useState(false); // State for showing toast
  const [isLoading, setIsLoading] = useState(false); 
  const fileInputRef = useRef(null); // Create a reference for the file input
  
  const postHotelData = async () => {
    try {
      const formData1 = new FormData();
      formData1.append('name', formData.name);
      formData1.append('about', formData.about);
      formData1.append('admin_id', formData.admin_id);
      formData1.append('address', formData.address);
      formData1.append('latitude', formData.latitude);
      formData1.append('longitude', formData.longitude);

      // Append each image file to FormData with the correct field name ('images')
      images.forEach((image, index) => {
        formData1.append('images', image);
      });

      const yourToken = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...(yourToken && { Authorization: `Bearer ${yourToken}` }),
        },
      };

      const response = await axios.post(`${baseUrl}create_hotel`, formData1, config);

      console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to post hotel data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    const requiredFields = ['name', 'about', 'address', 'latitude', 'longitude'];
    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      alert(`Please fill in the following fields: ${emptyFields.join(', ')}`);
      return;
    }

    try {
      // Handle form submission here, e.g., send data to server
      setIsLoading(true);
      await postHotelData();

      // Show success toast
      setIsLoading(false);
      setShowToast(true);

      // Clear form data and images after successful submission
      setFormData({
        name: '',
        about: '',
        admin_id: localStorage.getItem('adminId'),
        address: '',
        latitude: '',
        longitude: '',
      });
      setImages([]); // Clear images array
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear file input
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error if necessary
    }
  };

  return (
    <div className="container mt-5">
      <h2>Add Villas Here</h2>
      <br />
      <div className="container mt-2 border p-4">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="about">
            <Form.Label>About</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="about"
              required
              value={formData.about}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              required
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <div className='row'>
            <div className='col-md-6'>
              <Form.Group controlId="latitude">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="latitude"
                  required
                  value={formData.latitude}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <br />
            <div className='col-md-6'>
              <Form.Group controlId="longitude">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="longitude"
                  required
                  value={formData.longitude}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <br />
          <Form.Group controlId="images">
            <Form.Label>Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              required
              onChange={handleImageChange}
              ref={fileInputRef} // Attach the ref to the file input
            />
          </Form.Group>
          <br />
          <br />
          {isLoading ? (
            <Spinner animation="border" role="status">
            </Spinner>
          ) : (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
          <br />
          <br />
        </Form>
      </div>
      {/* Toast Notification */}
      {showToast && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            minWidth: '250px',
            backgroundColor: '#28a745', // Green background for success
            color: 'white', // White text color
          }}
        >
          <Toast.Body>Villa added successfully!</Toast.Body>
        </Toast>
      )}
      <br />
      <br />
    </div>
  );
};

export default AddHotel;
