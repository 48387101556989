import Spinner from 'react-bootstrap/Spinner';

function Loader() {
  return (
    <div className="container">
  <div className="row">
    <div className="col d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className='center'>
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
      </div>
    </div>
  </div>
</div>
 
  );
}

export default Loader;