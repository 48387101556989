import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <Container className="about-us mt-5">
      <h1 className="text-center mb-4">Discover the Magic of TBK Villas</h1>
      <Row className="mb-4">
        <Col>
          <p>
            Step into a world where luxury meets tranquility. TBK Villas stands as one of Goa's most esteemed villa groups, offering fully-equipped havens that promise both privacy and comfort. Renowned for our exceptional customer satisfaction and superior service quality, we've carved out a name that guests trust and cherish.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <p>
            Nestled in the heart of Goa, our luxury villas are designed to provide the best in comfort and style. Each villa is a private oasis, featuring modern amenities, elegant decor, and serene surroundings. Whether you're looking for a romantic getaway, a family vacation, or a serene retreat, TBK Villas has the perfect space for you.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <p>
            Our commitment to excellence is reflected in every aspect of our service. From personalized concierge services to meticulously maintained properties, we go above and beyond to ensure your stay is nothing short of spectacular. Relax by your private pool, enjoy a gourmet meal prepared by our in-house chef, or explore the vibrant culture of Goa – the choice is yours.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <p>
            At TBK Villas, we strive to make your stay as peaceful and memorable as possible. Here's what sets us apart:
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={4} className="stat">
          <h2>Over 10 Exclusive Villas</h2>
          <p>Choose from a selection of luxurious villas, each offering unique charm and sophistication.</p>
        </Col>
        <Col md={4} className="stat">
          <h2>1,020+ Tour Partners</h2>
          <p>Our extensive network of tour operators ensures that you have access to the best experiences Goa has to offer.</p>
        </Col>
        <Col md={4} className="stat">
          <h2>540+ Hotels & Accommodations</h2>
          <p>We collaborate with a wide range of accommodations to provide comprehensive travel solutions.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <p>
            Experience the epitome of luxury and comfort with TBK Villas. Your dream stay in Goa awaits.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
